<template>
  <b-form class="my-3" @submit.stop.prevent="onSubmit">
    <b-card title="Lifestyle questions">
      <b-card-text>
        <div class="form-group row">
          <label class="col-sm-12 col-form-label"
            >How many units of alcohol do you consume in a typical week?
            <a href="#unitsExample" data-toggle="modal"
              ><i class="fas fa-info-circle"></i></a
            ><b-link v-b-modal.modal-center-alcohol-units
              ><b-icon
                v-b-modal.modal-center-alcohol-units
                icon="info-circle-fill"
                class="h5 mb-0"
                variant="primary"
              ></b-icon></b-link
          ></label>
          <div class="col-sm-12 text-right">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.alcoholUnits"
                :options="[
                  { text: 'None', value: 'lsqAlcoholA1' },
                  { text: '1-10', value: 'lsqAlcoholA2' },
                  { text: '11-14', value: 'lsqAlcoholA3' },
                  { text: '15-21', value: 'lsqAlcoholA4' },
                  { text: '22+', value: 'lsqAlcoholA5' },
                ]"
                buttons
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div
              class="btn-group flex-wrap btn-group-toggle"
              data-toggle="buttons"
            >
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="alcoholUnits"
                  value="lsqAlcoholA1"
                  id="lsqAlcoholA1"
                  autocomplete="off"
                />
                None
              </label>
              <label class="btn btn-info }">
                <input
                  type="radio"
                  name="alcoholUnits"
                  value="lsqAlcoholA2"
                  id="lsqAlcoholA2"
                  autocomplete="off"
                />
                1-10
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="alcoholUnits"
                  value="lsqAlcoholA3"
                  id="lsqAlcoholA3"
                  autocomplete="off"
                />
                11-14
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="alcoholUnits"
                  value="lsqAlcoholA4"
                  id="lsqAlcoholA4"
                  autocomplete="off"
                />
                15-21
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="alcoholUnits"
                  value="lsqAlcoholA5"
                  id="lsqAlcoholA5"
                  autocomplete="off"
                />
                22+
              </label>
            </div> -->
          </div>
        </div>

        <div class="form-group row" id="alcoholFrequency">
          <label class="col-sm-12 col-form-label"
            >How often do you have an alcoholic drink?</label
          >
          <div class="col-sm-12 text-right">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.alcoholFrequency"
                :options="[
                  { text: 'Never or < Monthly/Never', value: 'lsqAlcoholB1' },
                  { text: 'Once a month', value: 'lsqAlcoholB2' },
                  { text: 'Once a week', value: 'lsqAlcoholB3' },
                  { text: '2-3 days p/w', value: 'lsqAlcoholB4' },
                  { text: '4+ p/w', value: 'lsqAlcoholB5' },
                ]"
                buttons
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div
              class="btn-group flex-wrap btn-group-toggle"
              data-toggle="buttons"
            >
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="alcoholFrequency"
                  value="lsqAlcoholB1"
                  id="lsqAlcoholB1"
                  autocomplete="off"
                />
                Never or &lt; Monthly/Never
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="alcoholFrequency"
                  value="lsqAlcoholB2"
                  id="lsqAlcoholB2"
                  autocomplete="off"
                />
                Once a month
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="alcoholFrequency"
                  value="lsqAlcoholB3"
                  id="lsqAlcoholB3"
                  autocomplete="off"
                />
                Once a week
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="alcoholFrequency"
                  value="lsqAlcoholB4"
                  id="lsqAlcoholB4"
                  autocomplete="off"
                />
                2-3 days p/w
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="alcoholFrequency"
                  value="lsqAlcoholB5"
                  id="lsqAlcoholB5"
                  autocomplete="off"
                />
                4+ p/w
              </label>
            </div> -->
          </div>
        </div>
        <div class="form-group row" id="alcoholOften">
          <label class="col-sm-12 col-form-label"
            >How often do you have 6 (women) or 8 (men) units or more on a
            single occasion?</label
          >
          <div class="col-sm-12 text-right">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.alcoholConsumption"
                :options="[
                  { text: 'Never', value: 'lsqAlcoholC1' },
                  { text: '< Monthly', value: 'lsqAlcoholC2' },
                  { text: 'Once a month', value: 'lsqAlcoholC3' },
                  { text: 'Once a week', value: 'lsqAlcoholC4' },
                  { text: '2-3 days p/w', value: 'lsqAlcoholC5' },
                  { text: '4+ p/w', value: 'lsqAlcoholC6' },
                ]"
                buttons
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div
              class="btn-group flex-wrap btn-group-toggle"
              data-toggle="buttons"
            >
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="alcoholConsumption"
                  value="lsqAlcoholC1"
                  id="lsqAlcoholC1"
                  autocomplete="off"
                />
                Never
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="alcoholConsumption"
                  value="lsqAlcoholC2"
                  id="lsqAlcoholC2"
                  autocomplete="off"
                />
                &lt; Monthly
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="alcoholConsumption"
                  value="lsqAlcoholC3"
                  id="lsqAlcoholC3"
                  autocomplete="off"
                />
                Once a month
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="alcoholConsumption"
                  value="lsqAlcoholC4"
                  id="lsqAlcoholC4"
                  autocomplete="off"
                />
                Once a week
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="alcoholConsumption"
                  value="lsqAlcoholC5"
                  id="lsqAlcoholC5"
                  autocomplete="off"
                />
                2-3 days p/w
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="alcoholConsumption"
                  value="lsqAlcoholC6"
                  id="lsqAlcoholC6"
                  autocomplete="off"
                />
                4+ p/w
              </label>
            </div> -->
          </div>
        </div>

        <div>
          <b-modal
            id="modal-center-alcohol-units"
            centered
            title="Alcohol Units"
            ok-only
            size="lg"
          >
            <img src="/images/MWC-Alcohol-Units_v5-2.png" class="img-fluid" />
          </b-modal>
        </div>
      </b-card-text>
      <b-card-footer>
        <b-button @click="setStep(4)" class="btn btn-hruk-btn" variant="primary"
          >Previous</b-button
        >
        <b-button
          type="submit"
          class="btn float-right"
          v-bind:disabled="disabled"
          >Next</b-button
        >
      </b-card-footer>
    </b-card>
  </b-form>
</template>

<script>
import { getMedicalAnswers, saveMedicalAnswers } from "../modules/Answers";
export default {
  name: "LifestyleDrinking",
  data: () => ({
    answers: {
      alcoholUnits: null,
      alcoholFrequency: null,
      alcoholConsumption: null,
    },
  }),
  async mounted() {
    const answers = await getMedicalAnswers();
    if (answers.length === 0) {
      return;
    }
    const alcoholUnits = answers.filter((answer) => answer.question === "35");
    if (alcoholUnits[0]) {
      this.answers.alcoholUnits = alcoholUnits[0].answer;
    }
    const alcoholFrequency = answers.filter(
      (answer) => answer.question === "36"
    );
    if (alcoholFrequency[0]) {
      this.answers.alcoholFrequency = alcoholFrequency[0].answer;
    }
    const alcoholConsumption = answers.filter(
      (answer) => answer.question === "37"
    );
    if (alcoholConsumption[0]) {
      this.answers.alcoholConsumption = alcoholConsumption[0].answer;
    }
  },
  methods: {
    async onSubmit() {
      const lsAnswers = {
        alcoholUnits: { question: 35, result: this.answers.alcoholUnits },
        alcoholFrequency: {
          question: 36,
          result: this.answers.alcoholFrequency,
        },
        alcoholConsumption: {
          question: 37,
          result: this.answers.alcoholConsumption,
        },
      };
      await saveMedicalAnswers(lsAnswers);
      this.$store.dispatch("setStep", 6);
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
  },
  computed: {
    disabled: function () {
      return this.answers.alcoholUnits === null ||
        this.answers.alcoholFrequency === null ||
        this.answers.alcoholConsumption === null
        ? true
        : false;
    },
  },
};
</script>
