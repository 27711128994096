import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    preassessmentAnswers: null,
    step: null,
    campaignCode: null,
    patient: null,
    sessionId: null,
    token:
      "8ef66e3bc2749010d3fb496226e9802eb53a2ea41e8dcfa4dbf530bdb28263eb9e5da6c593a04c285d5ca7e875348ac47d4b9d7427dd72e5a933132f",
    url: "https://efp.app.welltechsolutions.co.uk",
    // url: "https://127.0.0.1:8000",
  },
  getters: {},
  mutations: {
    setPreassessmentAnswers(state, payload) {
      state.preassessmentAnswers = payload;
    },
    setPreassessmentAnswer(state, payload) {
      Object.entries(payload).forEach(function ([key, value]) {
        state.preassessmentAnswers[key] = value;
      });
    },
    step(state, payload) {
      state.step = payload;
    },
    setCampaign(state, payload) {
      state.campaignCode = payload;
    },
    setPatient(state, payload) {
      state.patient = payload;
    },
    setSession(state, payload) {
      state.sessionId = payload;
    },
  },
  actions: {
    setPreassessmentAnswersAction({ commit }, payload) {
      commit("setPreassessmentAnswers", payload);
    },
    setPreassessmentAnswerAction({ commit }, payload) {
      commit("setPreassessmentAnswer", payload);
    },
    setStep({ commit }, payload) {
      commit("step", payload);
    },
    setCampaign({ commit }, payload) {
      commit("setCampaign", payload);
    },
    setPatient({ commit }, payload) {
      commit("setPatient", payload);
    },
    setSession({ commit }, payload) {
      commit("setSession", payload);
    },
  },
  modules: {},
});
