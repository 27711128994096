<template>
  <div>
    <div class="d-md-flex d-sm-none d-none justify-content-center mt-5">
      <div>
        <router-link to="/">
          <img src="/images/logo.png" class="" style="max-height: 100px" />
        </router-link>
      </div>
    </div>

    <div class="d-md-none d-sm-flex d-flex justify-content-center mt-1 mb-2">
      <div class="">
        <div class="my-3">
          <router-link to="/">
            <img src="/images/logo-mobile.png" style="max-width: 200px" />
          </router-link>
        </div>
        <!-- <div class="text-center">
          <router-link to="/">
            <img
              src="/images/FAQ.png"
              class="pl-3 img-fluid"
              style="max-height: 69px"
            />
          </router-link>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
