var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"my-3",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-card',{attrs:{"title":"Lifestyle questions"}},[_c('b-card-text',[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-12 col-form-label"},[_vm._v("How many units of alcohol do you consume in a typical week? "),_c('a',{attrs:{"href":"#unitsExample","data-toggle":"modal"}},[_c('i',{staticClass:"fas fa-info-circle"})]),_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-center-alcohol-units",modifiers:{"modal-center-alcohol-units":true}}]},[_c('b-icon',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-center-alcohol-units",modifiers:{"modal-center-alcohol-units":true}}],staticClass:"h5 mb-0",attrs:{"icon":"info-circle-fill","variant":"primary"}})],1)],1),_c('div',{staticClass:"col-sm-12 text-right"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: 'None', value: 'lsqAlcoholA1' },
                { text: '1-10', value: 'lsqAlcoholA2' },
                { text: '11-14', value: 'lsqAlcoholA3' },
                { text: '15-21', value: 'lsqAlcoholA4' },
                { text: '22+', value: 'lsqAlcoholA5' } ],"buttons":""},model:{value:(_vm.answers.alcoholUnits),callback:function ($$v) {_vm.$set(_vm.answers, "alcoholUnits", $$v)},expression:"answers.alcoholUnits"}})],1)],1)]),_c('div',{staticClass:"form-group row",attrs:{"id":"alcoholFrequency"}},[_c('label',{staticClass:"col-sm-12 col-form-label"},[_vm._v("How often do you have an alcoholic drink?")]),_c('div',{staticClass:"col-sm-12 text-right"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: 'Never or < Monthly/Never', value: 'lsqAlcoholB1' },
                { text: 'Once a month', value: 'lsqAlcoholB2' },
                { text: 'Once a week', value: 'lsqAlcoholB3' },
                { text: '2-3 days p/w', value: 'lsqAlcoholB4' },
                { text: '4+ p/w', value: 'lsqAlcoholB5' } ],"buttons":""},model:{value:(_vm.answers.alcoholFrequency),callback:function ($$v) {_vm.$set(_vm.answers, "alcoholFrequency", $$v)},expression:"answers.alcoholFrequency"}})],1)],1)]),_c('div',{staticClass:"form-group row",attrs:{"id":"alcoholOften"}},[_c('label',{staticClass:"col-sm-12 col-form-label"},[_vm._v("How often do you have 6 (women) or 8 (men) units or more on a single occasion?")]),_c('div',{staticClass:"col-sm-12 text-right"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: 'Never', value: 'lsqAlcoholC1' },
                { text: '< Monthly', value: 'lsqAlcoholC2' },
                { text: 'Once a month', value: 'lsqAlcoholC3' },
                { text: 'Once a week', value: 'lsqAlcoholC4' },
                { text: '2-3 days p/w', value: 'lsqAlcoholC5' },
                { text: '4+ p/w', value: 'lsqAlcoholC6' } ],"buttons":""},model:{value:(_vm.answers.alcoholConsumption),callback:function ($$v) {_vm.$set(_vm.answers, "alcoholConsumption", $$v)},expression:"answers.alcoholConsumption"}})],1)],1)]),_c('div',[_c('b-modal',{attrs:{"id":"modal-center-alcohol-units","centered":"","title":"Alcohol Units","ok-only":"","size":"lg"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/images/MWC-Alcohol-Units_v5-2.png"}})])],1)]),_c('b-card-footer',[_c('b-button',{staticClass:"btn btn-hruk-btn",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.setStep(4)}}},[_vm._v("Previous")]),_c('b-button',{staticClass:"btn float-right",attrs:{"type":"submit","disabled":_vm.disabled}},[_vm._v("Next")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }