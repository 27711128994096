<template>
  <b-form class="my-3" @submit.stop.prevent="onSubmit">
    <b-card title="Medical questions">
      <b-card-text>
        <p class="lead text-center">
          The first part of the questionnaire is to answer questions about your
          medical history along with other relevant questions relating to your
          assessment.
        </p>
        <p class="lead text-center">Do any of the following apply to you?</p>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group row">
              <label class="col-sm-9 col-form-label">Pacemaker</label>
              <div class="col-sm-3">
                <b-button-group>
                  <b-form-radio-group
                    v-model="answers.medicalPacemaker"
                    :options="[
                      { text: 'Yes', value: '1' },
                      { text: 'No', value: '0' },
                    ]"
                    buttons
                    size="sm"
                  ></b-form-radio-group>
                </b-button-group>
                <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalPacemaker"
                      value="1"
                      autocomplete="off"
                    />
                    Yes
                  </label>
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalPacemaker"
                      value="0"
                      autocomplete="off"
                    />
                    No
                  </label>
                </div> -->
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-9 col-form-label">Pregnant</label>
              <div class="col-sm-3">
                <b-button-group>
                  <b-form-radio-group
                    v-model="answers.medicalPregnant"
                    :options="[
                      { text: 'Yes', value: '1' },
                      { text: 'No', value: '0' },
                    ]"
                    buttons
                    size="sm"
                  ></b-form-radio-group>
                </b-button-group>
                <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalPregnant"
                      value="1"
                      autocomplete="off"
                    />
                    Yes
                  </label>
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalPregnant"
                      value="0"
                      autocomplete="off"
                    />
                    No
                  </label>
                </div> -->
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-9 col-form-label"
                >Chronic kidney disease</label
              >
              <div class="col-sm-3">
                <b-button-group>
                  <b-form-radio-group
                    v-model="answers.medicalChronicKidney"
                    :options="[
                      { text: 'Yes', value: '1' },
                      { text: 'No', value: '0' },
                    ]"
                    buttons
                    size="sm"
                  ></b-form-radio-group>
                </b-button-group>
                <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalChronicKidney"
                      value="1"
                      autocomplete="off"
                    />
                    Yes
                  </label>
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalChronicKidney"
                      value="0"
                      autocomplete="off"
                    />
                    No
                  </label>
                </div> -->
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-9 col-form-label"
                >Rheumatoid arthritis</label
              >
              <div class="col-sm-3">
                <b-button-group>
                  <b-form-radio-group
                    v-model="answers.medicalRheumatoidArthritis"
                    :options="[
                      { text: 'Yes', value: '1' },
                      { text: 'No', value: '0' },
                    ]"
                    buttons
                    size="sm"
                  ></b-form-radio-group>
                </b-button-group>
                <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalRheumatoidArthritis"
                      value="1"
                      autocomplete="off"
                    />
                    Yes
                  </label>
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalRheumatoidArthritis"
                      value="0"
                      autocomplete="off"
                    />
                    No
                  </label>
                </div> -->
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-9 col-form-label">Atrial fibriliation</label>
              <div class="col-sm-3">
                <b-button-group>
                  <b-form-radio-group
                    v-model="answers.medicalAtrialFib"
                    :options="[
                      { text: 'Yes', value: '1' },
                      { text: 'No', value: '0' },
                    ]"
                    buttons
                    size="sm"
                  ></b-form-radio-group>
                </b-button-group>
                <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalAtrialFib"
                      value="1"
                      autocomplete="off"
                    />
                    Yes
                  </label>
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalAtrialFib"
                      value="0"
                      autocomplete="off"
                    />
                    No
                  </label>
                </div> -->
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-9 col-form-label">Diabetic</label>
              <div class="col-sm-3">
                <b-button-group>
                  <b-form-radio-group
                    v-model="answers.medicalDiabetic"
                    :options="[
                      { text: 'Yes', value: '1' },
                      { text: 'No', value: '0' },
                    ]"
                    buttons
                    size="sm"
                  ></b-form-radio-group>
                </b-button-group>
                <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalDiabetic"
                      value="1"
                      autocomplete="off"
                    />
                    Yes
                  </label>
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalDiabetic"
                      value="0"
                      autocomplete="off"
                    />
                    No
                  </label>
                </div> -->
              </div>
            </div>

            <div
              class="form-group row"
              id="medicalDiabetesType"
              v-if="answers.medicalDiabetic === '1'"
            >
              <label class="col-sm-9 col-form-label">Diabetes type</label>
              <div class="col-sm-3">
                <b-button-group>
                  <b-form-radio-group
                    v-model="answers.medicalDiabetesType"
                    :options="[
                      { text: 'Type 1', value: '1' },
                      { text: 'Type 2', value: '2' },
                    ]"
                    buttons
                    size="sm"
                  ></b-form-radio-group>
                </b-button-group>
                <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalDiabetesType"
                      value="type1"
                      autocomplete="off"
                    />
                    Type 1
                  </label>
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalDiabetesType"
                      value="type2"
                      autocomplete="off"
                    />
                    Type 2
                  </label>
                </div> -->
              </div>
            </div>
          </div>

          <div class="col-md-7 offset-md-1">
            <div class="form-group row">
              <label class="offset-sm-2 col-sm-7 col-form-label"
                >Angina/Blood clots (last 6 months)</label
              >
              <div class="col-sm-3">
                <b-button-group>
                  <b-form-radio-group
                    v-model="answers.medicalAngina"
                    :options="[
                      { text: 'Yes', value: '1' },
                      { text: 'No', value: '0' },
                    ]"
                    buttons
                    size="sm"
                  ></b-form-radio-group>
                </b-button-group>
                <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalAngina"
                      value="1"
                      autocomplete="off"
                    />
                    Yes
                  </label>
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalAngina"
                      value="0"
                      autocomplete="off"
                    />
                    No
                  </label>
                </div> -->
              </div>
            </div>

            <div class="form-group row">
              <label class="offset-sm-2 col-sm-7 col-form-label"
                >Eye surgery (last 6 months)</label
              >
              <div class="col-sm-3">
                <b-button-group>
                  <b-form-radio-group
                    v-model="answers.medicalEyesurgery"
                    :options="[
                      { text: 'Yes', value: '1' },
                      { text: 'No', value: '0' },
                    ]"
                    buttons
                    size="sm"
                  ></b-form-radio-group>
                </b-button-group>
                <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalEyesurgery"
                      value="1"
                      autocomplete="off"
                    />
                    Yes
                  </label>
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalEyesurgery"
                      value="0"
                      autocomplete="off"
                    />
                    No
                  </label>
                </div> -->
              </div>
            </div>

            <div class="form-group row">
              <label class="offset-sm-2 col-sm-7 col-form-label"
                >Abdominal surgery (last 6 months)</label
              >
              <div class="col-sm-3">
                <b-button-group>
                  <b-form-radio-group
                    v-model="answers.medicalAbdominal"
                    :options="[
                      { text: 'Yes', value: '1' },
                      { text: 'No', value: '0' },
                    ]"
                    buttons
                    size="sm"
                  ></b-form-radio-group>
                </b-button-group>
                <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalAbdominal"
                      value="1"
                      autocomplete="off"
                    />
                    Yes
                  </label>
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalAbdominal"
                      value="0"
                      autocomplete="off"
                    />
                    No
                  </label>
                </div> -->
              </div>
            </div>

            <div class="form-group row">
              <label class="offset-sm-2 col-sm-7 col-form-label"
                >Asthma medication</label
              >
              <div class="col-sm-3">
                <b-button-group>
                  <b-form-radio-group
                    v-model="answers.medicalHistoryAsthma"
                    :options="[
                      { text: 'Yes', value: '1' },
                      { text: 'No', value: '0' },
                    ]"
                    buttons
                    size="sm"
                  ></b-form-radio-group>
                </b-button-group>
                <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalHistoryAsthma"
                      value="1"
                      autocomplete="off"
                    />
                    Yes
                  </label>
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalHistoryAsthma"
                      value="0"
                      autocomplete="off"
                    />
                    No
                  </label>
                </div> -->
              </div>
            </div>

            <div class="form-group row">
              <label class="offset-sm-2 col-sm-7 col-form-label"
                >Blood pressure medication</label
              >
              <div class="col-sm-3">
                <b-button-group>
                  <b-form-radio-group
                    v-model="answers.medicalBloodPressureTreatment"
                    :options="[
                      { text: 'Yes', value: '1' },
                      { text: 'No', value: '0' },
                    ]"
                    buttons
                    size="sm"
                  ></b-form-radio-group>
                </b-button-group>
                <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalBloodPressureTreatment"
                      value="1"
                      autocomplete="off"
                    />
                    Yes
                  </label>
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalBloodPressureTreatment"
                      value="0"
                      autocomplete="off"
                    />
                    No
                  </label>
                </div> -->
              </div>
            </div>

            <div class="form-group row">
              <label class="offset-sm-2 col-sm-7 col-form-label"
                >Cholesterol medication</label
              >
              <div class="col-sm-3">
                <b-button-group>
                  <b-form-radio-group
                    v-model="answers.medicalCholesterolMedicine"
                    :options="[
                      { text: 'Yes', value: '1' },
                      { text: 'No', value: '0' },
                    ]"
                    buttons
                    size="sm"
                  ></b-form-radio-group>
                </b-button-group>
                <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalCholesterolMeds"
                      value="1"
                      autocomplete="off"
                    />
                    Yes
                  </label>
                  <label class="btn btn-info btn-sm">
                    <input
                      type="radio"
                      name="medicalCholesterolMeds"
                      value="0"
                      autocomplete="off"
                    />
                    No
                  </label>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </b-card-text>
      <b-card-footer>
        <!-- <b-button @click="setStep(1)" class="btn btn-hruk-btn" variant="primary"
          >Previous</b-button
        > -->
        <b-button
          type="submit"
          class="btn float-right"
          v-bind:disabled="disabled"
          >Next</b-button
        >
      </b-card-footer>
    </b-card>
  </b-form>
</template>

<script>
import { getMedicalAnswers, saveMedicalAnswers } from "../modules/Answers";
export default {
  name: "MedicalHistory",
  data: () => ({
    answers: {
      medicalPacemaker: null,
      medicalPregnant: null,
      medicalChronicKidney: null,
      medicalRheumatoidArthritis: null,
      medicalAtrialFib: null,
      medicalDiabetic: null,
      medicalDiabetesType: null,
      medicalAngina: null,
      medicalEyesurgery: null,
      medicalAbdominal: null,
      medicalHistoryAsthma: null,
      medicalBloodPressureTreatment: null,
      medicalCholesterolMedicine: null,
    },
  }),
  async mounted() {
    const answers = await getMedicalAnswers();
    if (answers.length === 0) {
      return;
    }
    const pacemaker = answers.filter((answer) => answer.question === "60");
    if (pacemaker[0]) {
      this.answers.medicalPacemaker = pacemaker[0].answer;
    }
    const pregnant = answers.filter((answer) => answer.question === "61");
    if (pregnant[0]) {
      this.answers.medicalPregnant = pregnant[0].answer;
    }
    const kidney = answers.filter((answer) => answer.question === "63");
    if (kidney[0]) {
      this.answers.medicalChronicKidney = kidney[0].answer;
    }
    const arthritis = answers.filter((answer) => answer.question === "64");
    if (arthritis[0]) {
      this.answers.medicalRheumatoidArthritis = arthritis[0].answer;
    }
    const atrialFib = answers.filter((answer) => answer.question === "60");
    if (atrialFib[0]) {
      this.answers.medicalAtrialFib = atrialFib[0].answer;
    }
    const diabetic = answers.filter((answer) => answer.question === "74");
    if (diabetic[0]) {
      this.answers.medicalDiabetic =
        diabetic[0].answer === "0" ? diabetic[0].answer : "1";
      if (diabetic[0].answer !== "0") {
        this.answers.medicalDiabetesType = diabetic[0].answer;
      }
    }
    const angina = answers.filter((answer) => answer.question === "66");
    if (angina[0]) {
      this.answers.medicalAngina = angina[0].answer;
    }
    const eyesurgery = answers.filter((answer) => answer.question === "67");
    if (eyesurgery[0]) {
      this.answers.medicalEyesurgery = eyesurgery[0].answer;
    }
    const abdominal = answers.filter((answer) => answer.question === "68");
    if (abdominal[0]) {
      this.answers.medicalAbdominal = abdominal[0].answer;
    }
    const asthma = answers.filter((answer) => answer.question === "69");
    if (asthma[0]) {
      this.answers.medicalHistoryAsthma = asthma[0].answer;
    }
    const bloodpressuremeds = answers.filter(
      (answer) => answer.question === "70"
    );
    if (bloodpressuremeds[0]) {
      this.answers.medicalBloodPressureTreatment = bloodpressuremeds[0].answer;
    }
    const cholesterolmeds = answers.filter(
      (answer) => answer.question === "71"
    );
    if (cholesterolmeds[0]) {
      this.answers.medicalCholesterolMedicine = cholesterolmeds[0].answer;
    }
  },
  methods: {
    async onSubmit() {
      let medicalDiabetes = "0";
      if (this.answers.medicalDiabetic !== "0") {
        medicalDiabetes = this.answers.medicalDiabetesType;
      }
      const medAnswers = [
        { question: 60, result: this.answers.medicalPacemaker },
        { question: 61, result: this.answers.medicalPregnant },
        { question: 63, result: this.answers.medicalChronicKidney },
        { question: 64, result: this.answers.medicalRheumatoidArthritis },
        { question: 65, result: this.answers.medicalAtrialFib },
        { question: 74, result: medicalDiabetes },
        { question: 66, result: this.answers.medicalAngina },
        { question: 67, result: this.answers.medicalEyesurgery },
        { question: 68, result: this.answers.medicalAbdominal },
        { question: 69, result: this.answers.medicalHistoryAsthma },
        { question: 70, result: this.answers.medicalBloodPressureTreatment },
        { question: 71, result: this.answers.medicalCholesterolMedicine },
      ];
      await saveMedicalAnswers(medAnswers);
      this.$store.dispatch("setStep", 3);
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
  },
  computed: {
    disabled: function () {
      return this.answers.medicalPacemaker === null ||
        this.answers.medicalPregnant === null ||
        this.answers.medicalDiabetic === null ||
        this.answers.medicalHistoryAsthma === null ||
        this.answers.medicalCholesterolMedicine === null ||
        this.answers.medicalChronicKidney === null ||
        this.answers.medicalRheumatoidArthritis === null ||
        this.answers.medicalAtrialFib === null ||
        this.answers.medicalAngina === null ||
        this.answers.medicalEyesurgery === null ||
        this.answers.medicalAbdominal === null
        ? true
        : false;
    },
  },
};
</script>

<style></style>
