import store from "../store";
import axios from "axios";

export async function getMedicalAnswers() {
  if (store.state.sessionId) {
    try {
      const result = await axios.get(
        `${store.state.url}/apiv1/healthcheck/answer`,
        {
          params: {
            casesessionid: store.state.sessionId,
          },
          headers: {
            Authorization: `Bearer ${store.state.token}`,
          },
        }
      );
      return result.data;
    } catch (e) {
      throw new Error(e.message);
    }
  }
}

export async function saveMedicalAnswers(payload) {
  if (store.state.sessionId) {
    const pay = Object.entries(payload)
      .filter(([_, v]) => v != null && v !== "")
      .reduce((pay, [k, v]) => ({ ...pay, [k]: v }), {});

    const config = {
      headers: { Authorization: `Bearer ${store.state.token}` },
    };
    const body = {
      casesessionid: store.state.sessionId,
      answers: pay,
    };
    try {
      const result = await axios.post(
        `${store.state.url}/apiv1/healthcheck/answer`,
        body,
        config
      );
      return result.data;
    } catch (e) {
      throw new Error(e.message);
    }
  }
}
