var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"my-3",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-card',{attrs:{"title":"Lifestyle questions"}},[_c('b-card-text',[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-6 col-form-label"},[_vm._v("How many minutes of moderate-high intensity exercise do you do each week?")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: '0 > 30m', value: 'lsqMovementA1' },
                { text: '31-149m', value: 'lsqMovementA2' },
                { text: '150+', value: 'lsqMovementA3' } ],"buttons":""},model:{value:(_vm.answers.highIntenseActivity),callback:function ($$v) {_vm.$set(_vm.answers, "highIntenseActivity", $$v)},expression:"answers.highIntenseActivity"}})],1)],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-6 col-form-label"},[_vm._v("How many sessions of strength/resistance/weight training do you do each week?")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: '0', value: 'lsqMovementB1' },
                { text: '1', value: 'lsqMovementB2' },
                { text: '2+', value: 'lsqMovementB3' } ],"buttons":""},model:{value:(_vm.answers.weightTraining),callback:function ($$v) {_vm.$set(_vm.answers, "weightTraining", $$v)},expression:"answers.weightTraining"}})],1)],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-6 col-form-label"},[_vm._v("How much time do you spend each week doing leisure walking, cleaning or other low intensity activity?")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: '0 > 30m', value: 'lsqMovementC1' },
                { text: '31-149m', value: 'lsqMovementC2' },
                { text: '150+', value: 'lsqMovementC3' } ],"buttons":""},model:{value:(_vm.answers.lowIntenseActivity),callback:function ($$v) {_vm.$set(_vm.answers, "lowIntenseActivity", $$v)},expression:"answers.lowIntenseActivity"}})],1)],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-6 col-form-label"},[_vm._v("What is your biggest barrier to being more active?")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: 'Time', value: 'lsqMovementD1' },
                { text: 'Motivation', value: 'lsqMovementD2' },
                { text: 'Family', value: 'lsqMovementD3' },
                { text: 'Cost', value: 'lsqMovementD4' },
                { text: 'Other', value: 'lsqMovementD5' } ],"buttons":""},model:{value:(_vm.answers.activityBarrier),callback:function ($$v) {_vm.$set(_vm.answers, "activityBarrier", $$v)},expression:"answers.activityBarrier"}})],1)],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-6 col-form-label"},[_vm._v("In a typical day, please estimate how many hours you spend sitting (including commuting)?")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: '0 - 4h', value: 'lsqMovementE1' },
                { text: '4-8', value: 'lsqMovementE2' },
                { text: '8+', value: 'lsqMovementE3' } ],"buttons":""},model:{value:(_vm.answers.timeSitting),callback:function ($$v) {_vm.$set(_vm.answers, "timeSitting", $$v)},expression:"answers.timeSitting"}})],1)],1)])]),_c('b-card-footer',[_c('b-button',{staticClass:"btn btn-hruk-btn",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.setStep(6)}}},[_vm._v("Previous")]),_c('b-button',{staticClass:"btn float-right",attrs:{"type":"submit","disabled":_vm.disabled}},[_vm._v("Submit")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }