<template>
  <b-form class="my-3" @submit.stop.prevent="onSubmit">
    <b-card title="Lifestyle questions">
      <b-card-text>
        <p class="lead text-center">
          <strong style="color: red"
            >Good news, you are already half way through the process!</strong
          >
          The final part of the questionnaire is relating to lifestyle questions
          and this will take approximately 2-3 minutes.
        </p>
        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >Which describes your smoking status?</label
          >
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.smokingStatus"
                :options="[
                  { text: 'Non', value: 'lsqSmokingA1' },
                  { text: 'Ex more 1yr', value: 'lsqSmokingA2' },
                  { text: 'Ex less 1yr', value: 'lsqSmokingA3' },
                  { text: 'Social', value: 'lsqSmokingA4' },
                  { text: 'Daily', value: 'lsqSmokingA5' },
                ]"
                buttons
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div
              class="btn-group flex-wrap btn-group-toggle"
              data-toggle="buttons"
            >
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="smokingStatus"
                  id="lsqSmokingA1"
                  value="lsqSmokingA1"
                  autocomplete="off"
                />
                Non
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="smokingStatus"
                  id="lsqSmokingA2"
                  value="lsqSmokingA2"
                  autocomplete="off"
                />
                Ex more 1yr
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="smokingStatus"
                  id="lsqSmokingA3"
                  value="lsqSmokingA3"
                  autocomplete="off"
                />
                Ex less 1yr
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="smokingStatus"
                  id="lsqSmokingA4"
                  value="lsqSmokingA4"
                  autocomplete="off"
                />
                Social
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="smokingStatus"
                  id="lsqSmokingA5"
                  value="lsqSmokingA5"
                  autocomplete="off"
                />
                Daily
              </label>
            </div> -->
          </div>
        </div>

        <div
          class="form-group row"
          id="smokingAmount"
          v-if="
            answers.smokingStatus == 'lsqSmokingA4' ||
            answers.smokingStatus == 'lsqSmokingA5'
          "
        >
          <label class="col-sm-6 col-form-label"
            >On average, how many do you smoke per day?</label
          >
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.smokePerDay"
                :options="[
                  { text: '<10', value: 'lsqSmokingB1' },
                  { text: '11 - 19', value: 'lsqSmokingB2' },
                  { text: '20+', value: 'lsqSmokingB3' },
                ]"
                buttons
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div
              class="btn-group flex-wrap btn-group-toggle"
              data-toggle="buttons"
            >
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="smokePerDay"
                  value="lsqSmokingB1"
                  id="lsqSmokingB1"
                  autocomplete="off"
                />
                <10
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="smokePerDay"
                  value="lsqSmokingB2"
                  id="lsqSmokingB2"
                  autocomplete="off"
                />
                11 - 19
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="smokePerDay"
                  value="lsqSmokingB3"
                  id="lsqSmokingB3"
                  autocomplete="off"
                />
                20+
              </label>
            </div> -->
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >Do you use e-cigarettes?</label
          >
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.eCigarettes"
                :options="[
                  { text: 'Yes', value: 'lsqSmokingC1' },
                  { text: 'No', value: 'lsqSmokingC2' },
                ]"
                buttons
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div
              class="btn-group flex-wrap btn-group-toggle"
              data-toggle="buttons"
            >
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="eCigarettes"
                  value="lsqSmokingC1"
                  id="lsqSmokingC1"
                  autocomplete="off"
                />
                Yes
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="eCigarettes"
                  value="lsqSmokingC2"
                  id="lsqSmokingC2"
                  autocomplete="off"
                />
                No
              </label>
            </div> -->
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >Typically how many hours of sleep do you get each night?</label
          >
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.sleep"
                :options="[
                  { text: '4 or less', value: 'lsqSleepA1' },
                  { text: '5-6', value: 'lsqSleepA2' },
                  { text: '7-9', value: 'lsqSleepA3' },
                  { text: '9+', value: 'lsqSleepA4' },
                ]"
                buttons
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div
              class="btn-group flex-wrap btn-group-toggle"
              data-toggle="buttons"
            >
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="sleep"
                  value="lsqSleepA1"
                  id="lsqSleepA1"
                  autocomplete="off"
                />
                4 or less
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="sleep"
                  value="lsqSleepA2"
                  id="lsqSleepA2"
                  autocomplete="off"
                />
                5-6
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="sleep"
                  value="lsqSleepA3"
                  id="lsqSleepA3"
                  autocomplete="off"
                />
                7-9
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="sleep"
                  value="lsqSleepA4"
                  id="lsqSleepA4"
                  autocomplete="off"
                />
                9+
              </label>
            </div> -->
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >In general, how do you rate the quality of your sleep?</label
          >
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.sleepQuality"
                :options="[
                  { text: 'Excellent', value: 'lsqSleepB1' },
                  { text: 'Very Good', value: 'lsqSleepB2' },
                  { text: 'Good', value: 'lsqSleepB3' },
                  { text: 'Average', value: 'lsqSleepB4' },
                  { text: 'Poor', value: 'lsqSleepB5' },
                ]"
                buttons
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div
              class="btn-group flex-wrap btn-group-toggle"
              data-toggle="buttons"
            >
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="sleepQuality"
                  value="lsqSleepB1"
                  id="lsqSleepB1"
                  autocomplete="off"
                />
                Excellent
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="sleepQuality"
                  value="lsqSleepB2"
                  id="lsqSleepB2"
                  autocomplete="off"
                />
                Very Good
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="sleepQuality"
                  value="lsqSleepB3"
                  id="lsqSleepB3"
                  autocomplete="off"
                />
                Good
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="sleepQuality"
                  value="lsqSleepB4"
                  id="lsqSleepB4"
                  autocomplete="off"
                />
                Average
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="sleepQuality"
                  value="lsqSleepB5"
                  id="lsqSleepB5"
                  autocomplete="off"
                />
                Poor
              </label>
            </div> -->
          </div>
        </div>
      </b-card-text>
      <b-card-footer>
        <b-button @click="setStep(3)" class="btn btn-hruk-btn" variant="primary"
          >Previous</b-button
        >
        <b-button
          type="submit"
          class="btn float-right"
          v-bind:disabled="disabled"
          >Next</b-button
        >
      </b-card-footer>
    </b-card>
  </b-form>
</template>

<script>
import { getMedicalAnswers, saveMedicalAnswers } from "../modules/Answers";
export default {
  name: "FamilyHistory",
  data: () => ({
    answers: {
      smokingStatus: null,
      smokePerDay: null,
      eCigarettes: null,
      sleep: null,
      sleepQuality: null,
    },
  }),
  async mounted() {
    const answers = await getMedicalAnswers();
    if (answers.length === 0) {
      return;
    }
    const smokingStatus = answers.filter((answer) => answer.question === "30");
    if (smokingStatus[0]) {
      this.answers.smokingStatus = smokingStatus[0].answer;
    }
    const eCigarettes = answers.filter((answer) => answer.question === "32");
    if (eCigarettes[0]) {
      this.answers.eCigarettes = eCigarettes[0].answer;
    }
    const smokePerDay = answers.filter((answer) => answer.question === "31");
    if (smokePerDay[0]) {
      this.answers.smokePerDay = smokePerDay[0].answer;
    }
    const sleep = answers.filter((answer) => answer.question === "33");
    if (sleep[0]) {
      this.answers.sleep = sleep[0].answer;
    }
    const sleepQuality = answers.filter((answer) => answer.question === "34");
    if (sleepQuality[0]) {
      this.answers.sleepQuality = sleepQuality[0].answer;
    }
  },
  methods: {
    async onSubmit() {
      const lsAnswers = {
        smokingStatus: { question: 30, result: this.answers.smokingStatus },
        eCigarettes: { question: 32, result: this.answers.eCigarettes },
        smokePerDay: { question: 31, result: this.answers.smokePerDay },
        sleep: { question: 33, result: this.answers.sleep },
        sleepQuality: { question: 34, result: this.answers.sleepQuality },
      };
      await saveMedicalAnswers(lsAnswers);
      this.$store.dispatch("setStep", 5);
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
  },
  computed: {
    disabled: function () {
      return (
        this.answers.smokingStatus === null ||
        this.answers.eCigarettes === null ||
        ((this.answers.smokingStatus === "lsqSmokingA4" ||
          this.answers.smokingStatus === "lsqSmokingA5") &&
          this.answers.smokePerDay === null)
      );
    },
  },
};
</script>
