<template>
  <div class="campaign-code-container">
    <b-form @submit.stop.prevent="onSubmit" class="mt-5">
      <div class="d-flex hruk-campaign-code">
        <div class="flex-grow-1">
          <div>
            <div class="form-group">
              <b-form-input
                v-model="code"
                class="h-hrukbox form-control"
                placeholder="Enter campaign code"
              ></b-form-input>
            </div>
          </div>
        </div>
        <b-button type="submit" class="btn btn-flubtn btn-hruksubmit h-hrukbox">
          <div class="d-flex justify-content-between align-items-center">
            <div>Submit</div>
            <div>
              <img src="/images/chevron.png" class="ml-2 mr-1" />
            </div>
          </div>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import * as Campaign from "./../modules/Campaign";
export default {
  name: "Campaign",
  components: {},
  data() {
    return {
      code: null,
    };
  },
  methods: {
    async onSubmit() {
      if (this.code) {
        const campaignExists = await Campaign.getCampaign(this.code);
        if (campaignExists.result) {
          this.$store.dispatch("setCampaign", this.code);
          this.$store.dispatch("setStep", 1);
        }
        return false;
      }
    },
  },
  async created() {},
};
</script>
