import store from "../store";
import axios from "axios";

export async function getPatient() {
  // try {
  //   var {
  //     data: { get_patient: patient },
  //   } = await API.graphql({
  //     query: get_patient,
  //     variables: { id: patientId },
  //     authMode: "API_KEY",
  //   });
  //   return patient;
  // } catch (e) {
  //   throw new Error(e.errors[0].message);
  // }
}

export async function savePatient(payload) {
  const config = {
    headers: { Authorization: `Bearer ${store.state.token}` },
  };
  const body = {
    ...payload,
  };
  try {
    const result = await axios.post(
      `${store.state.url}/apiv1/intake/create`,
      body,
      config
    );
    return result.data;
  } catch (e) {
    throw new Error(e.message);
  }
}
