var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"my-3",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-card',{attrs:{"title":"Medical questions"}},[_c('b-card-text',[_c('p',{staticClass:"lead text-center"},[_vm._v(" Do you have "),_c('strong',{staticStyle:{"color":"red"}},[_vm._v("family history")]),_vm._v(" of any of the following? If unknown, select "),_c('em',[_vm._v("No")]),_vm._v(". ")]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"offset-sm-4 col-sm-2 col-form-label"},[_vm._v("Blood pressure")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: 'Yes', value: '1' },
                { text: 'No', value: '0' } ],"buttons":"","size":"sm"},model:{value:(_vm.answers.medicalFamilyBloodpressure),callback:function ($$v) {_vm.$set(_vm.answers, "medicalFamilyBloodpressure", $$v)},expression:"answers.medicalFamilyBloodpressure"}})],1)],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"offset-sm-4 col-sm-2 col-form-label"},[_vm._v("Cholesterol")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: 'Yes', value: '1' },
                { text: 'No', value: '0' } ],"buttons":"","size":"sm"},model:{value:(_vm.answers.medicalFamilyCholesterol),callback:function ($$v) {_vm.$set(_vm.answers, "medicalFamilyCholesterol", $$v)},expression:"answers.medicalFamilyCholesterol"}})],1)],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"offset-sm-4 col-sm-2 col-form-label"},[_vm._v("Diabetes")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: 'Yes', value: '1' },
                { text: 'No', value: '0' } ],"buttons":"","size":"sm"},model:{value:(_vm.answers.medicalFamilyDiabetes),callback:function ($$v) {_vm.$set(_vm.answers, "medicalFamilyDiabetes", $$v)},expression:"answers.medicalFamilyDiabetes"}})],1)],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"offset-sm-4 col-sm-2 col-form-label"},[_vm._v("Bowel cancer")]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"btn-group btn-group-toggle",attrs:{"data-toggle":"buttons"}},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                  { text: 'Yes', value: '1' },
                  { text: 'No', value: '0' } ],"buttons":"","size":"sm"},model:{value:(_vm.answers.medicalFamilyBowel),callback:function ($$v) {_vm.$set(_vm.answers, "medicalFamilyBowel", $$v)},expression:"answers.medicalFamilyBowel"}})],1)],1)])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"offset-sm-4 col-sm-2 col-form-label"},[_vm._v("Stroke")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: 'Yes', value: '1' },
                { text: 'No', value: '0' } ],"buttons":"","size":"sm"},model:{value:(_vm.answers.medicalFamilyStroke),callback:function ($$v) {_vm.$set(_vm.answers, "medicalFamilyStroke", $$v)},expression:"answers.medicalFamilyStroke"}})],1)],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"offset-sm-2 col-sm-4 col-form-label",staticStyle:{"margin-top":"-17px"}},[_vm._v("Angina/heart attack in a 1st degree relative before the age of 60")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: 'Yes', value: '1' },
                { text: 'No', value: '0' } ],"buttons":"","size":"sm"},model:{value:(_vm.answers.medicalAnginaInRelative),callback:function ($$v) {_vm.$set(_vm.answers, "medicalAnginaInRelative", $$v)},expression:"answers.medicalAnginaInRelative"}})],1)],1)])]),_c('b-card-footer',[_c('b-button',{staticClass:"btn btn-hruk-btn",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.setStep(2)}}},[_vm._v("Previous")]),_c('b-button',{staticClass:"btn float-right",attrs:{"type":"submit","disabled":_vm.disabled}},[_vm._v("Next")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }