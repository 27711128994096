<template>
  <b-form class="my-3" @submit.stop.prevent="onSubmit">
    <b-card title="Lifestyle questions">
      <b-card-text>
        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >How would you describe your dietary habits?</label
          >
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.diet"
                :options="[
                  { text: 'Excellent', value: 'lsqNutritionA1' },
                  { text: 'Very Good', value: 'lsqNutritionA2' },
                  { text: 'Good', value: 'lsqNutritionA3' },
                  { text: 'Average', value: 'lsqNutritionA4' },
                  { text: 'Poor', value: 'lsqNutritionA5' },
                ]"
                buttons
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div
              class="btn-group flex-wrap btn-group-toggle"
              data-toggle="buttons"
            >
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="diet"
                  value="lsqNutritionA1"
                  id="lsqNutritionA1"
                  autocomplete="off"
                />
                Excellent
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="diet"
                  value="lsqNutritionA2"
                  id="lsqNutritionA2"
                  autocomplete="off"
                />
                Very Good
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="diet"
                  value="lsqNutritionA3"
                  id="lsqNutritionA3"
                  autocomplete="off"
                />
                Good
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="diet"
                  value="lsqNutritionA4"
                  id="lsqNutritionA4"
                  autocomplete="off"
                />
                Average
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="diet"
                  value="lsqNutritionA5"
                  id="lsqNutritionA5"
                  autocomplete="off"
                />
                Poor
              </label>
            </div> -->
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >How many days per week do you eat 5 or more portions of fruit and
            veg?</label
          >
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.fruitAndVeg"
                :options="[
                  { text: '0', value: 'lsqNutritionB1' },
                  { text: '1 - 2', value: 'lsqNutritionB2' },
                  { text: '3 - 4', value: 'lsqNutritionB3' },
                  { text: '4 - 5', value: 'lsqNutritionB4' },
                  { text: '6 - 7', value: 'lsqNutritionB5' },
                ]"
                buttons
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div
              class="btn-group flex-wrap btn-group-toggle"
              data-toggle="buttons"
            >
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="lsqNutritionB"
                  value="lsqNutritionB1"
                  id="lsqNutritionB1"
                  autocomplete="off"
                />
                0
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="lsqNutritionB"
                  value="lsqNutritionB2"
                  id="lsqNutritionB2"
                  autocomplete="off"
                />
                1 - 2
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="lsqNutritionB"
                  value="lsqNutritionB3"
                  id="lsqNutritionB3"
                  autocomplete="off"
                />
                3 - 4
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="lsqNutritionB"
                  value="lsqNutritionB4"
                  id="lsqNutritionB4"
                  autocomplete="off"
                />
                4 - 5
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="lsqNutritionB"
                  value="lsqNutritionB5"
                  id="lsqNutritionB5"
                  autocomplete="off"
                />
                6 - 7
              </label>
            </div> -->
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >On average, how many high sugar and high fat processed foods do you
            have a day?</label
          >
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.sugaryFattyFood"
                :options="[
                  { text: '0', value: 'lsqNutritionC1' },
                  { text: '1 - 2', value: 'lsqNutritionC2' },
                  { text: '3 - 4', value: 'lsqNutritionC3' },
                  { text: '5+', value: 'lsqNutritionC4' },
                ]"
                buttons
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div
              class="btn-group flex-wrap btn-group-toggle"
              data-toggle="buttons"
            >
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="sugaryFattyFood"
                  value="lsqNutritionC1"
                  id="lsqNutritionC1"
                  autocomplete="off"
                />
                0
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="sugaryFattyFood"
                  value="lsqNutritionC2"
                  id="lsqNutritionC2"
                  autocomplete="off"
                />
                1-2
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="sugaryFattyFood"
                  value="lsqNutritionC3"
                  id="lsqNutritionC3"
                  autocomplete="off"
                />
                3-4
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="sugaryFattyFood"
                  value="lsqNutritionC4"
                  id="lsqNutritionC4"
                  autocomplete="off"
                />
                5+
              </label>
            </div> -->
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >How often do you drink 6-8 glasses of water each day?</label
          >
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.drinkWater"
                :options="[
                  { text: '0-3 days', value: 'lsqNutritionD1' },
                  { text: '4-5 days', value: 'lsqNutritionD2' },
                  { text: '6-7 days', value: 'lsqNutritionD3' },
                ]"
                buttons
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div
              class="btn-group flex-wrap btn-group-toggle"
              data-toggle="buttons"
            >
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="drinkWater"
                  value="lsqNutritionD1"
                  id="lsqNutritionD1"
                  autocomplete="off"
                />
                0-3 days
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="drinkWater"
                  value="lsqNutritionD2"
                  id="lsqNutritionD2"
                  autocomplete="off"
                />
                4-5 days
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="drinkWater"
                  value="lsqNutritionD3"
                  id="lsqNutritionD3"
                  autocomplete="off"
                />
                6-7 days
              </label>
            </div> -->
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >Over the last 6-8 weeks, how would you rate your stress
            levels?</label
          >
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.stressLevels"
                :options="[
                  { text: 'Low', value: 'lsqStressA1' },
                  { text: 'Medium', value: 'lsqStressA2' },
                  { text: 'High', value: 'lsqStressA3' },
                ]"
                buttons
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div
              class="btn-group flex-wrap btn-group-toggle"
              data-toggle="buttons"
            >
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="stressLevels"
                  value="lsqStressA1"
                  id="lsqStressA1"
                  autocomplete="off"
                />
                Low
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="stressLevels"
                  value="lsqStressA2"
                  id="lsqStressA2"
                  autocomplete="off"
                />
                Medium
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="stressLevels"
                  value="lsqStressA3"
                  id="lsqStressA3"
                  autocomplete="off"
                />
                High
              </label>
            </div> -->
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >How often do demands placed upon you have a negative wellbeing
            effect?</label
          >
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.effectOnWellbeing"
                :options="[
                  { text: 'Never', value: 'lsqStressB1' },
                  { text: 'Rarely', value: 'lsqStressB2' },
                  { text: 'Sometimes', value: 'lsqStressB3' },
                  { text: 'Mostly', value: 'lsqStressB4' },
                  { text: 'Always', value: 'lsqStressB5' },
                ]"
                buttons
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div
              class="btn-group flex-wrap btn-group-toggle"
              data-toggle="buttons"
            >
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="effectOnWellbeing"
                  value="lsqStressB1"
                  id="lsqStressB1"
                  autocomplete="off"
                />
                Never
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="effectOnWellbeing"
                  value="lsqStressB2"
                  id="lsqStressB2"
                  autocomplete="off"
                />
                Rarely
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="effectOnWellbeing"
                  value="lsqStressB3"
                  id="lsqStressB3"
                  autocomplete="off"
                />
                Sometimes
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="effectOnWellbeing"
                  value="lsqStressB4"
                  id="lsqStressB4"
                  autocomplete="off"
                />
                Mostly
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="effectOnWellbeing"
                  value="lsqStressB5"
                  id="lsqStressB5"
                  autocomplete="off"
                />
                Always
              </label>
            </div> -->
          </div>
        </div>
      </b-card-text>
      <b-card-footer>
        <b-button @click="setStep(5)" class="btn btn-hruk-btn" variant="primary"
          >Previous</b-button
        >
        <b-button
          type="submit"
          class="btn float-right"
          v-bind:disabled="disabled"
          >Next</b-button
        >
      </b-card-footer>
    </b-card>
  </b-form>
</template>

<script>
import { getMedicalAnswers, saveMedicalAnswers } from "../modules/Answers";
export default {
  name: "LifestyleDiet",
  data: () => ({
    answers: {
      diet: null,
      fruitAndVeg: null,
      sugaryFattyFood: null,
      drinkWater: null,
      stressLevels: null,
      effectOnWellbeing: null,
    },
  }),
  async mounted() {
    const answers = await getMedicalAnswers();
    const diet = answers.filter((answer) => answer.question === "38");
    if (diet[0]) {
      this.answers.diet = diet[0].answer;
    }
    const fruitAndVeg = answers.filter((answer) => answer.question === "39");
    if (fruitAndVeg[0]) {
      this.answers.fruitAndVeg = fruitAndVeg[0].answer;
    }
    const sugaryFattyFood = answers.filter(
      (answer) => answer.question === "48"
    );
    if (sugaryFattyFood[0]) {
      this.answers.sugaryFattyFood = sugaryFattyFood[0].answer;
    }
    const drinkWater = answers.filter((answer) => answer.question === "40");
    if (drinkWater[0]) {
      this.answers.drinkWater = drinkWater[0].answer;
    }
    const stressLevels = answers.filter((answer) => answer.question === "41");
    if (stressLevels[0]) {
      this.answers.stressLevels = stressLevels[0].answer;
    }
    const effectOnWellbeing = answers.filter(
      (answer) => answer.question === "42"
    );
    if (effectOnWellbeing[0]) {
      this.answers.effectOnWellbeing = effectOnWellbeing[0].answer;
    }
  },
  methods: {
    async onSubmit() {
      const lsAnswers = {
        diet: { question: 38, result: this.answers.diet },
        fruitAndVeg: { question: 39, result: this.answers.fruitAndVeg },
        sugaryFattyFood: { question: 48, result: this.answers.sugaryFattyFood },
        drinkWater: { question: 40, result: this.answers.drinkWater },
        stressLevels: { question: 41, result: this.answers.stressLevels },
        effectOnWellbeing: {
          question: 42,
          result: this.answers.effectOnWellbeing,
        },
      };
      await saveMedicalAnswers(lsAnswers);
      this.$store.dispatch("setStep", 7);
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
  },
  computed: {
    disabled: function () {
      return this.answers.diet === null ||
        this.answers.fruitAndVeg === null ||
        this.answers.sugaryFattyFood === null ||
        this.answers.drinkWater === null ||
        this.answers.stressLevels === null ||
        this.answers.effectOnWellbeing === null
        ? true
        : false;
    },
  },
};
</script>
