<template>
  <b-form class="my-3" @submit.stop.prevent="onSubmit">
    <b-card title="Lifestyle questions">
      <b-card-text>
        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >How many minutes of moderate-high intensity exercise do you do each
            week?</label
          >
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.highIntenseActivity"
                :options="[
                  { text: '0 > 30m', value: 'lsqMovementA1' },
                  { text: '31-149m', value: 'lsqMovementA2' },
                  { text: '150+', value: 'lsqMovementA3' },
                ]"
                buttons
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div
              class="btn-group flex-wrap btn-group-toggle"
              data-toggle="buttons"
            >
              <label class="btn btn-info">
                <input
                  type="radio"
                  value="lsqMovementA1"
                  id="lsqMovementA1"
                  autocomplete="off"
                  name="highIntenseActivity"
                />
                0 &gt; 30m
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  value="lsqMovementA2"
                  id="lsqMovementA2"
                  autocomplete="off"
                  name="highIntenseActivity"
                />
                31-149m
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  value="lsqMovementA3"
                  id="lsqMovementA3"
                  autocomplete="off"
                  name="highIntenseActivity"
                />
                150+
              </label>
            </div> -->
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >How many sessions of strength/resistance/weight training do you do
            each week?</label
          >
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.weightTraining"
                :options="[
                  { text: '0', value: 'lsqMovementB1' },
                  { text: '1', value: 'lsqMovementB2' },
                  { text: '2+', value: 'lsqMovementB3' },
                ]"
                buttons
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div
              class="btn-group flex-wrap btn-group-toggle"
              data-toggle="buttons"
            >
              <label class="btn btn-info">
                <input
                  type="radio"
                  value="lsqMovementB1"
                  id="lsqMovementB1"
                  autocomplete="off"
                  name="weightTraining"
                />
                0
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  value="lsqMovementB2"
                  id="lsqMovementB2"
                  autocomplete="off"
                  name="weightTraining"
                />
                1
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  value="lsqMovementB3"
                  id="lsqMovementB3"
                  autocomplete="off"
                  name="weightTraining"
                />
                2+
              </label>
            </div> -->
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >How much time do you spend each week doing leisure walking,
            cleaning or other low intensity activity?</label
          >
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.lowIntenseActivity"
                :options="[
                  { text: '0 > 30m', value: 'lsqMovementC1' },
                  { text: '31-149m', value: 'lsqMovementC2' },
                  { text: '150+', value: 'lsqMovementC3' },
                ]"
                buttons
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div
              class="btn-group flex-wrap btn-group-toggle"
              data-toggle="buttons"
            >
              <label class="btn btn-info">
                <input
                  type="radio"
                  value="lsqMovementC1"
                  id="lsqMovementC1"
                  autocomplete="off"
                  name="lowIntenseActivity"
                />
                0 &gt; 30m
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  value="lsqMovementC2"
                  id="lsqMovementC2"
                  autocomplete="off"
                  name="lowIntenseActivity"
                />
                31-149m
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  value="lsqMovementC3"
                  id="lsqMovementC3"
                  autocomplete="off"
                  name="lowIntenseActivity"
                />
                150+
              </label>
            </div> -->
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >What is your biggest barrier to being more active?</label
          >
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.activityBarrier"
                :options="[
                  { text: 'Time', value: 'lsqMovementD1' },
                  { text: 'Motivation', value: 'lsqMovementD2' },
                  { text: 'Family', value: 'lsqMovementD3' },
                  { text: 'Cost', value: 'lsqMovementD4' },
                  { text: 'Other', value: 'lsqMovementD5' },
                ]"
                buttons
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div
              class="btn-group flex-wrap btn-group-toggle"
              data-toggle="buttons"
            >
              <label class="btn btn-info">
                <input
                  type="radio"
                  value="lsqMovementD1"
                  id="lsqMovementD1"
                  autocomplete="off"
                  name="activityBarrier"
                />
                Time
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  value="lsqMovementD2"
                  id="lsqMovementD2"
                  autocomplete="off"
                  name="activityBarrier"
                />
                Motivation
              </label>
              <label class="btn btn-info"> Family </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  value="lsqMovementD4"
                  id="lsqMovementD4"
                  autocomplete="off"
                  name="activityBarrier"
                />
                Cost
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  value="lsqMovementD5"
                  id="lsqMovementD5"
                  autocomplete="off"
                  name="activityBarrier"
                />
                Other
              </label>
            </div> -->
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >In a typical day, please estimate how many hours you spend sitting
            (including commuting)?</label
          >
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.timeSitting"
                :options="[
                  { text: '0 - 4h', value: 'lsqMovementE1' },
                  { text: '4-8', value: 'lsqMovementE2' },
                  { text: '8+', value: 'lsqMovementE3' },
                ]"
                buttons
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div
              class="btn-group flex-wrap btn-group-toggle"
              data-toggle="buttons"
            >
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="timeSitting"
                  id="lsqMovementE1"
                  autocomplete="off"
                  value="lsqMovementE1"
                />
                0 - 4h
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="timeSitting"
                  id="lsqMovementE2"
                  autocomplete="off"
                  value="lsqMovementE2"
                />
                4-8
              </label>
              <label class="btn btn-info">
                <input
                  type="radio"
                  name="timeSitting"
                  id="lsqMovementE3"
                  autocomplete="off"
                  value="lsqMovementE3"
                />
                8+
              </label>
            </div> -->
          </div>
        </div>
      </b-card-text>
      <b-card-footer>
        <b-button @click="setStep(6)" class="btn btn-hruk-btn" variant="primary"
          >Previous</b-button
        >
        <b-button
          type="submit"
          class="btn float-right"
          v-bind:disabled="disabled"
          >Submit</b-button
        >
      </b-card-footer>
    </b-card>
  </b-form>
</template>

<script>
import { getMedicalAnswers, saveMedicalAnswers } from "../modules/Answers";
export default {
  name: "LifestyleExercise",
  data: () => ({
    answers: {
      highIntenseActivity: null,
      weightTraining: null,
      lowIntenseActivity: null,
      activityBarrier: null,
      timeSitting: null,
    },
  }),
  async mounted() {
    const answers = await getMedicalAnswers();
    const highIntenseActivity = answers.filter(
      (answer) => answer.question === "43"
    );
    if (highIntenseActivity[0]) {
      this.answers.highIntenseActivity = highIntenseActivity[0].answer;
    }
    const weightTraining = answers.filter((answer) => answer.question === "44");
    if (weightTraining[0]) {
      this.answers.weightTraining = weightTraining[0].answer;
    }
    const lowIntenseActivity = answers.filter(
      (answer) => answer.question === "45"
    );
    if (lowIntenseActivity[0]) {
      this.answers.lowIntenseActivity = lowIntenseActivity[0].answer;
    }
    const activityBarrier = answers.filter(
      (answer) => answer.question === "46"
    );
    if (lowIntenseActivity[0]) {
      this.answers.activityBarrier = activityBarrier[0].answer;
    }
    const timeSitting = answers.filter((answer) => answer.question === "47");
    if (timeSitting[0]) {
      this.answers.timeSitting = timeSitting[0].answer;
    }
  },
  methods: {
    async onSubmit() {
      const lsAnswers = {
        highIntenseActivity: {
          question: 43,
          result: this.answers.highIntenseActivity,
        },
        weightTraining: { question: 44, result: this.answers.weightTraining },
        lowIntenseActivity: {
          question: 45,
          result: this.answers.lowIntenseActivity,
        },
        activityBarrier: { question: 46, result: this.answers.activityBarrier },
        timeSitting: {
          question: 47,
          result: this.answers.timeSitting,
        },
      };
      await saveMedicalAnswers(lsAnswers);
      this.$store.dispatch("setStep", 8);
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
  },
  computed: {
    disabled: function () {
      return (
        this.answers.highIntenseActivity === null ||
        this.answers.weightTraining === null ||
        this.answers.lowIntenseActivity === null ||
        this.answers.activityBarrier === null ||
        this.answers.timeSitting === null
      );
    },
  },
};
</script>
