<template>
  <b-form class="my-3" @submit.stop.prevent="onSubmit">
    <b-card title="Medical questions">
      <b-card-text>
        <p class="lead text-center">
          Do you have <strong style="color: red">family history</strong> of any
          of the following? If unknown, select <em>No</em>.
        </p>
        <div class="form-group row">
          <label class="offset-sm-4 col-sm-2 col-form-label"
            >Blood pressure</label
          >
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.medicalFamilyBloodpressure"
                :options="[
                  { text: 'Yes', value: '1' },
                  { text: 'No', value: '0' },
                ]"
                buttons
                size="sm"
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
              <label class="btn btn-info btn-sm">
                <input
                  type="radio"
                  name="medicalFamilyBloodpressure"
                  value="1"
                  autocomplete="off"
                />
                Yes
              </label>
              <label class="btn btn-info btn-sm">
                <input
                  type="radio"
                  name="medicalFamilyBloodpressure"
                  value="0"
                  autocomplete="off"
                />
                No
              </label>
            </div> -->
          </div>
        </div>

        <div class="form-group row">
          <label class="offset-sm-4 col-sm-2 col-form-label">Cholesterol</label>
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.medicalFamilyCholesterol"
                :options="[
                  { text: 'Yes', value: '1' },
                  { text: 'No', value: '0' },
                ]"
                buttons
                size="sm"
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
              <label class="btn btn-info btn-sm">
                <input
                  type="radio"
                  name="medicalFamilyCholesterol"
                  value="1"
                  autocomplete="off"
                />
                Yes
              </label>
              <label class="btn btn-info btn-sm">
                <input
                  type="radio"
                  name="medicalFamilyCholesterol"
                  value="0"
                  autocomplete="off"
                />
                No
              </label>
            </div> -->
          </div>
        </div>

        <div class="form-group row">
          <label class="offset-sm-4 col-sm-2 col-form-label">Diabetes</label>
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.medicalFamilyDiabetes"
                :options="[
                  { text: 'Yes', value: '1' },
                  { text: 'No', value: '0' },
                ]"
                buttons
                size="sm"
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
              <label class="btn btn-info btn-sm">
                <input
                  type="radio"
                  name="medicalFamilyDiabetes"
                  value="1"
                  autocomplete="off"
                />
                Yes
              </label>
              <label class="btn btn-info btn-sm">
                <input
                  type="radio"
                  name="medicalFamilyDiabetes"
                  value="0"
                  autocomplete="off"
                />
                No
              </label>
            </div> -->
          </div>
        </div>

        <div class="form-group row">
          <label class="offset-sm-4 col-sm-2 col-form-label"
            >Bowel cancer</label
          >
          <div class="col-sm-6">
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
              <b-button-group>
                <b-form-radio-group
                  v-model="answers.medicalFamilyBowel"
                  :options="[
                    { text: 'Yes', value: '1' },
                    { text: 'No', value: '0' },
                  ]"
                  buttons
                  size="sm"
                ></b-form-radio-group>
              </b-button-group>
              <!-- <label class="btn btn-info btn-sm">
                <input
                  type="radio"
                  name="medicalFamilyBowel"
                  value="1"
                  autocomplete="off"
                />
                Yes
              </label>
              <label class="btn btn-info btn-sm">
                <input
                  type="radio"
                  name="medicalFamilyBowel"
                  value="0"
                  autocomplete="off"
                />
                No
              </label> -->
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="offset-sm-4 col-sm-2 col-form-label">Stroke</label>
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.medicalFamilyStroke"
                :options="[
                  { text: 'Yes', value: '1' },
                  { text: 'No', value: '0' },
                ]"
                buttons
                size="sm"
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
              <label class="btn btn-info btn-sm">
                <input
                  type="radio"
                  name="medicalFamilyStroke"
                  value="1"
                  autocomplete="off"
                />
                Yes
              </label>
              <label class="btn btn-info btn-sm">
                <input
                  type="radio"
                  name="medicalFamilyStroke"
                  value="0"
                  autocomplete="off"
                />
                No
              </label>
            </div> -->
          </div>
        </div>

        <div class="form-group row">
          <label
            class="offset-sm-2 col-sm-4 col-form-label"
            style="margin-top: -17px"
            >Angina/heart attack in a 1st degree relative before the age of
            60</label
          >
          <div class="col-sm-6">
            <b-button-group>
              <b-form-radio-group
                v-model="answers.medicalAnginaInRelative"
                :options="[
                  { text: 'Yes', value: '1' },
                  { text: 'No', value: '0' },
                ]"
                buttons
                size="sm"
              ></b-form-radio-group>
            </b-button-group>
            <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
              <label class="btn btn-info btn-sm">
                <input
                  type="radio"
                  name="medicalAnginaInRelative"
                  value="1"
                  autocomplete="off"
                />
                Yes
              </label>
              <label class="btn btn-info btn-sm">
                <input
                  type="radio"
                  name="medicalAnginaInRelative"
                  value="0"
                  autocomplete="off"
                />
                No
              </label>
            </div> -->
          </div>
        </div>
      </b-card-text>
      <b-card-footer>
        <b-button @click="setStep(2)" class="btn btn-hruk-btn" variant="primary"
          >Previous</b-button
        >
        <b-button
          type="submit"
          class="btn float-right"
          v-bind:disabled="disabled"
          >Next</b-button
        >
      </b-card-footer>
    </b-card>
  </b-form>
</template>

<script>
import { getMedicalAnswers, saveMedicalAnswers } from "../modules/Answers";
export default {
  name: "FamilyHistory",
  data: () => ({
    answers: {
      medicalFamilyBloodpressure: null,
      medicalFamilyCholesterol: null,
      medicalFamilyDiabetes: null,
      medicalFamilyBowel: null,
      medicalFamilyStroke: null,
      medicalAnginaInRelative: null,
    },
  }),
  async mounted() {
    const answers = await getMedicalAnswers();

    const medBloodPressure = answers.filter(
      (answer) => answer.question === "101"
    );
    if (medBloodPressure[0]) {
      this.answers.medicalFamilyBloodpressure = medBloodPressure[0].answer;
    }
    const medCholesterol = answers.filter(
      (answer) => answer.question === "102"
    );
    if (medCholesterol[0]) {
      this.answers.medicalFamilyCholesterol = medCholesterol[0].answer;
    }
    const medDiabetes = answers.filter((answer) => answer.question === "104");
    if (medDiabetes[0]) {
      this.answers.medicalFamilyDiabetes = medDiabetes[0].answer;
    }
    const medBowel = answers.filter((answer) => answer.question === "103");
    if (medBowel[0]) {
      this.answers.medicalFamilyBowel = medBowel[0].answer;
    }
    const medStroke = answers.filter((answer) => answer.question === "105");
    if (medStroke[0]) {
      this.answers.medicalFamilyStroke = medStroke[0].answer;
    }
    const medAnginaInRelative = answers.filter(
      (answer) => answer.question === "106"
    );
    if (medAnginaInRelative[0]) {
      this.answers.medicalAnginaInRelative = medAnginaInRelative[0].answer;
    }
  },
  methods: {
    async onSubmit() {
      const medAnswers = [
        { question: 101, result: this.answers.medicalFamilyBloodpressure },
        { question: 102, result: this.answers.medicalFamilyCholesterol },
        { question: 104, result: this.answers.medicalFamilyDiabetes },
        { question: 103, result: this.answers.medicalFamilyBowel },
        { question: 105, result: this.answers.medicalFamilyStroke },
        { question: 106, result: this.answers.medicalAnginaInRelative },
      ];
      await saveMedicalAnswers(medAnswers);
      this.$store.dispatch("setStep", 4);
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
  },
  computed: {
    disabled: function () {
      return this.answers.medicalFamilyBloodpressure === null ||
        this.answers.medicalFamilyCholesterol === null ||
        this.answers.medicalFamilyDiabetes === null ||
        this.answers.medicalFamilyBowel === null ||
        this.answers.medicalFamilyStroke === null ||
        this.answers.medicalAnginaInRelative === null
        ? true
        : false;
    },
  },
};
</script>
