var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"my-3",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-card',{attrs:{"title":"Lifestyle questions"}},[_c('b-card-text',[_c('p',{staticClass:"lead text-center"},[_c('strong',{staticStyle:{"color":"red"}},[_vm._v("Good news, you are already half way through the process!")]),_vm._v(" The final part of the questionnaire is relating to lifestyle questions and this will take approximately 2-3 minutes. ")]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-6 col-form-label"},[_vm._v("Which describes your smoking status?")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: 'Non', value: 'lsqSmokingA1' },
                { text: 'Ex more 1yr', value: 'lsqSmokingA2' },
                { text: 'Ex less 1yr', value: 'lsqSmokingA3' },
                { text: 'Social', value: 'lsqSmokingA4' },
                { text: 'Daily', value: 'lsqSmokingA5' } ],"buttons":""},model:{value:(_vm.answers.smokingStatus),callback:function ($$v) {_vm.$set(_vm.answers, "smokingStatus", $$v)},expression:"answers.smokingStatus"}})],1)],1)]),(
          _vm.answers.smokingStatus == 'lsqSmokingA4' ||
          _vm.answers.smokingStatus == 'lsqSmokingA5'
        )?_c('div',{staticClass:"form-group row",attrs:{"id":"smokingAmount"}},[_c('label',{staticClass:"col-sm-6 col-form-label"},[_vm._v("On average, how many do you smoke per day?")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: '<10', value: 'lsqSmokingB1' },
                { text: '11 - 19', value: 'lsqSmokingB2' },
                { text: '20+', value: 'lsqSmokingB3' } ],"buttons":""},model:{value:(_vm.answers.smokePerDay),callback:function ($$v) {_vm.$set(_vm.answers, "smokePerDay", $$v)},expression:"answers.smokePerDay"}})],1)],1)]):_vm._e(),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-6 col-form-label"},[_vm._v("Do you use e-cigarettes?")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: 'Yes', value: 'lsqSmokingC1' },
                { text: 'No', value: 'lsqSmokingC2' } ],"buttons":""},model:{value:(_vm.answers.eCigarettes),callback:function ($$v) {_vm.$set(_vm.answers, "eCigarettes", $$v)},expression:"answers.eCigarettes"}})],1)],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-6 col-form-label"},[_vm._v("Typically how many hours of sleep do you get each night?")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: '4 or less', value: 'lsqSleepA1' },
                { text: '5-6', value: 'lsqSleepA2' },
                { text: '7-9', value: 'lsqSleepA3' },
                { text: '9+', value: 'lsqSleepA4' } ],"buttons":""},model:{value:(_vm.answers.sleep),callback:function ($$v) {_vm.$set(_vm.answers, "sleep", $$v)},expression:"answers.sleep"}})],1)],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-6 col-form-label"},[_vm._v("In general, how do you rate the quality of your sleep?")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: 'Excellent', value: 'lsqSleepB1' },
                { text: 'Very Good', value: 'lsqSleepB2' },
                { text: 'Good', value: 'lsqSleepB3' },
                { text: 'Average', value: 'lsqSleepB4' },
                { text: 'Poor', value: 'lsqSleepB5' } ],"buttons":""},model:{value:(_vm.answers.sleepQuality),callback:function ($$v) {_vm.$set(_vm.answers, "sleepQuality", $$v)},expression:"answers.sleepQuality"}})],1)],1)])]),_c('b-card-footer',[_c('b-button',{staticClass:"btn btn-hruk-btn",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.setStep(3)}}},[_vm._v("Previous")]),_c('b-button',{staticClass:"btn float-right",attrs:{"type":"submit","disabled":_vm.disabled}},[_vm._v("Next")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }