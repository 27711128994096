import store from "../store";
import axios from "axios";

export async function getCampaign(code) {
  // DEC2022
  const config = {
    headers: { Authorization: `Bearer ${store.state.token}` },
  };
  const body = {
    campaigncode: `${code}`,
  };
  try {
    const result = await axios.post(
      `${store.state.url}/apiv1/campaigncode/check`,
      body,
      config
    );
    return result.data;
  } catch (e) {
    throw new Error(e.message);
  }
}
