var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"my-3",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-card',{attrs:{"title":"Lifestyle questions"}},[_c('b-card-text',[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-6 col-form-label"},[_vm._v("How would you describe your dietary habits?")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: 'Excellent', value: 'lsqNutritionA1' },
                { text: 'Very Good', value: 'lsqNutritionA2' },
                { text: 'Good', value: 'lsqNutritionA3' },
                { text: 'Average', value: 'lsqNutritionA4' },
                { text: 'Poor', value: 'lsqNutritionA5' } ],"buttons":""},model:{value:(_vm.answers.diet),callback:function ($$v) {_vm.$set(_vm.answers, "diet", $$v)},expression:"answers.diet"}})],1)],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-6 col-form-label"},[_vm._v("How many days per week do you eat 5 or more portions of fruit and veg?")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: '0', value: 'lsqNutritionB1' },
                { text: '1 - 2', value: 'lsqNutritionB2' },
                { text: '3 - 4', value: 'lsqNutritionB3' },
                { text: '4 - 5', value: 'lsqNutritionB4' },
                { text: '6 - 7', value: 'lsqNutritionB5' } ],"buttons":""},model:{value:(_vm.answers.fruitAndVeg),callback:function ($$v) {_vm.$set(_vm.answers, "fruitAndVeg", $$v)},expression:"answers.fruitAndVeg"}})],1)],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-6 col-form-label"},[_vm._v("On average, how many high sugar and high fat processed foods do you have a day?")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: '0', value: 'lsqNutritionC1' },
                { text: '1 - 2', value: 'lsqNutritionC2' },
                { text: '3 - 4', value: 'lsqNutritionC3' },
                { text: '5+', value: 'lsqNutritionC4' } ],"buttons":""},model:{value:(_vm.answers.sugaryFattyFood),callback:function ($$v) {_vm.$set(_vm.answers, "sugaryFattyFood", $$v)},expression:"answers.sugaryFattyFood"}})],1)],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-6 col-form-label"},[_vm._v("How often do you drink 6-8 glasses of water each day?")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: '0-3 days', value: 'lsqNutritionD1' },
                { text: '4-5 days', value: 'lsqNutritionD2' },
                { text: '6-7 days', value: 'lsqNutritionD3' } ],"buttons":""},model:{value:(_vm.answers.drinkWater),callback:function ($$v) {_vm.$set(_vm.answers, "drinkWater", $$v)},expression:"answers.drinkWater"}})],1)],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-6 col-form-label"},[_vm._v("Over the last 6-8 weeks, how would you rate your stress levels?")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: 'Low', value: 'lsqStressA1' },
                { text: 'Medium', value: 'lsqStressA2' },
                { text: 'High', value: 'lsqStressA3' } ],"buttons":""},model:{value:(_vm.answers.stressLevels),callback:function ($$v) {_vm.$set(_vm.answers, "stressLevels", $$v)},expression:"answers.stressLevels"}})],1)],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-6 col-form-label"},[_vm._v("How often do demands placed upon you have a negative wellbeing effect?")]),_c('div',{staticClass:"col-sm-6"},[_c('b-button-group',[_c('b-form-radio-group',{attrs:{"options":[
                { text: 'Never', value: 'lsqStressB1' },
                { text: 'Rarely', value: 'lsqStressB2' },
                { text: 'Sometimes', value: 'lsqStressB3' },
                { text: 'Mostly', value: 'lsqStressB4' },
                { text: 'Always', value: 'lsqStressB5' } ],"buttons":""},model:{value:(_vm.answers.effectOnWellbeing),callback:function ($$v) {_vm.$set(_vm.answers, "effectOnWellbeing", $$v)},expression:"answers.effectOnWellbeing"}})],1)],1)])]),_c('b-card-footer',[_c('b-button',{staticClass:"btn btn-hruk-btn",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.setStep(5)}}},[_vm._v("Previous")]),_c('b-button',{staticClass:"btn float-right",attrs:{"type":"submit","disabled":_vm.disabled}},[_vm._v("Next")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }