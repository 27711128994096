<template>
  <div>
    <Campaign v-if="step === null" />
    <Profile v-if="step === 1" />
    <MedicalHistory v-if="step === 2" />
    <FamilyHistory v-if="step === 3" />
    <LifestyleSmoking v-if="step === 4" />
    <LifestyleDrinking v-if="step === 5" />
    <LifestyleDiet v-if="step === 6" />
    <LifestyleExercise v-if="step === 7" />
    <Complete v-if="step === 8" />
  </div>
</template>

<script>
import Campaign from "../components/Campaign";
import Profile from "../components/Profile";
import MedicalHistory from "../components/MedicalHistory";
import FamilyHistory from "../components/FamilyHistory";
import LifestyleSmoking from "../components/LifestyleSmoking";
import LifestyleDrinking from "../components/LifestyleDrinking";
import LifestyleDiet from "../components/LifestyleDiet";
import LifestyleExercise from "../components/LifestyleExercise";
import Complete from "../components/Complete";
export default {
  name: "LifestyleQuestionnaire",
  components: {
    Campaign,
    Profile,
    MedicalHistory,
    FamilyHistory,
    LifestyleSmoking,
    LifestyleDrinking,
    LifestyleDiet,
    LifestyleExercise,
    Complete,
  },
  methods: {
    changeStep(step) {
      this.$store.dispatch("setStep", step);
    },
  },
  computed: {
    step() {
      return this.$store.state.step;
    },
  },
};
</script>
