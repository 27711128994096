<template>
  <b-form class="my-3">
    <b-card>
      <b-card-text>
        <div class="container">
          <br />
          <div class="row">
            <div class="col-sm-3 text-center">
              <img src="/images/wellbeing-hub.png" class="img-fluid" />
            </div>
            <div class="col align-self-center">
              <p class="lead" style="">
                <strong style="color: green">All done!</strong> You may find the
                below information useful.
              </p>
            </div>
          </div>
          <br />
          <!-- <div class="row">
            <div class="col-sm-3 text-center">
              <a href="/" target="_blank">
                <img
                  src="/images/CONSENT.png"
                  class="img-fluid completeIconSub"
                />
              </a>
            </div>
            <div class="col align-self-center">
              <p class="lead">
                Please read the
                <a href="/" target="_blank">Informed Consent Form</a> for more
                information about your data.<br />
              </p>
            </div>
          </div> -->
          <br />
          <div class="row">
            <div class="col-sm-3 text-center align-self-center">
              <img
                src="/images/EXCLAIM.png"
                class="img-fluid completeIconSub"
              />
            </div>
            <div class="col align-self-center">
              <p class="lead">
                So that we can get the most accurate test results there is some
                important information that you need to be aware of before and
                during your assessment:
              </p>
              <br />
              <ul>
                <li>
                  <strong
                    >Please fast for 3 hours before your health
                    assessment.</strong
                  >
                  This means you to need avoid food, caffeine, sugary drinks,
                  smoking and vaping.
                </li>
                <li>
                  Since you are fasting
                  <strong>please make sure you drink plenty of water</strong> to
                  stay hydrated.
                </li>
                <li>
                  <strong>Please avoid exercise for 12 hours</strong> before
                  your assessment.
                </li>
              </ul>
            </div>
          </div>
          <!-- <br />
          <div class="row">
            <div class="col-sm-3 text-center">
              <a href="" target="_blank">
                <img
                  src="/images/Video_Start_Button.png"
                  class="img-fluid completeIconSub"
                />
              </a>
            </div>
            <div class="col align-self-center">
              <p class="lead">
                See what to expect in your assessment with this
                <a href="" target="_blank">90 second video</a>
              </p>
            </div>
          </div> -->
        </div>
      </b-card-text>
    </b-card>
  </b-form>
</template>

<script>
export default {};
</script>
